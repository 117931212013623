export type StringBoolean = "true" | "false";

export interface SignInFormValues {
  email: string;
  password: string;
  rememberEmail: StringBoolean | null;
  csrfToken?: string;
  referral?: string;
  oauthClientId?: string;
  ["g-recaptcha-response-v2"]?: string;
  ["g-recaptcha-response-v3"]?: string;
}

export enum SignInView {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}
