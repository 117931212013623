import { IconKeyFilled } from "@hubble/icons";
import { Button } from "@gemini-ui/design-system";
import { SigninTestIds } from "@gemini-ui/pages/SignIn/testIds";
import { useIntl } from "@gemini-ui/utils/intl";

type PasskeySignInCardProps = {
  startSignIn: () => void;
  isLoading: boolean;
};

export const PasskeySignInCard = ({ startSignIn, isLoading }: PasskeySignInCardProps) => {
  const { intl } = useIntl();

  return (
    <Button.Secondary
      leftElement={<IconKeyFilled />}
      loading={isLoading}
      size="lg"
      onClick={startSignIn}
      data-testid={SigninTestIds.PasskeySigninCard}
      style={{ width: "100%" }}
      mt={2}
    >
      {intl.formatMessage({ defaultMessage: "Sign in with passkey" })}
    </Button.Secondary>
  );
};
