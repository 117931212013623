import { useState } from "react";
import { EVENTS, track } from "@gemini-ui/analytics";
import { AuthenticationRequest } from "@gemini-ui/constants/initialData";
import { usePasskeyAuth } from "@gemini-ui/pages/Authy/AuthyForm/usePasskeyAuth";
import { trackOnboardingError } from "@gemini-ui/pages/register/trackOnboarding";
import axios, { AxiosError } from "@gemini-ui/services/axios";

export enum PasskeyErrorType {
  PasskeySigninDisabled = "passkeySignInDisabled",
  ErrorProcessing = "errorProcessing",
  PasskeySigninUnsupported = "passkeySigninUnsupported",
}

export const usePasskeySignin = (redirect: string) => {
  const { initiateAuth } = usePasskeyAuth(true);
  const [errorType, setErrorType] = useState<PasskeyErrorType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const startAuthProcess = (authRequest: AuthenticationRequest) => {
    const publicKeyCredentialRequestOptions = authRequest.assertion.assertion.publicKeyCredentialRequestOptions;
    const authSignedRequest = authRequest.signed;
    initiateAuth({
      onSuccess: handleSuccess,
      onError: handleError,
      publicKeyCredentialRequestOptions,
      authSignedRequest,
      redirect,
    });
  };

  const fetchProof = async () => {
    try {
      setIsLoading(true);
      // checks for browser support of webauthn credentials
      if (navigator.credentials && navigator.credentials.get && window.PublicKeyCredential) {
        const response = await axios.get<AuthenticationRequest>("/signin/passwordless");
        // support retrying and opening webauthn right away on success
        if (response.data) {
          startAuthProcess(response.data);
        }
      } else {
        setErrorType(PasskeyErrorType.PasskeySigninUnsupported);
        track(EVENTS.UNSUPPORTED_BROWSER_FOR_PASSKEY_SIGNIN.name);
      }
    } catch (e) {
      track(EVENTS.SIGNIN_WITH_PASSKEY_ERROR_FETCHING_PROOF.name, {
        [EVENTS.SIGNIN_WITH_PASSKEY_ERROR_FETCHING_PROOF.properties.Error]: e,
      });
      setErrorType(PasskeyErrorType.ErrorProcessing);
      trackOnboardingError({ message: `usePasskeySignin - fetch proof error`, error: e });
    } finally {
      setIsLoading(false);
    }
  };

  const retrySignin = () => {
    setErrorType(null);
    fetchProof();
  };

  const handleSuccess = (data: { redirect: string }) => {
    track(EVENTS.PASSKEY_LOGIN_SUCCESS);
    window.location.assign(data?.redirect || "/");
  };

  const handleError = (e: AxiosError<{ error: string }> | DOMException) => {
    track(EVENTS.PASSKEY_LOGIN_FAILED.name, { [EVENTS.PASSKEY_LOGIN_FAILED.properties.Error]: e.message });
    if (e instanceof AxiosError && e.response.data?.error === PasskeyErrorType.PasskeySigninDisabled) {
      setErrorType(PasskeyErrorType.PasskeySigninDisabled);
    } else {
      setErrorType(PasskeyErrorType.ErrorProcessing);
    }
  };

  const startSignin = () => {
    track(EVENTS.SIGNIN_WITH_PASSKEY_CLICKED);
    fetchProof();
  };

  return { startSignin, errorType, setErrorType, retrySignin, isLoading };
};
